






























































































import { Vue, Component, Prop, Watch, Emit, Ref } from "vue-property-decorator";
import Events from "./Detail/Events.vue";
import Transactions from "./Detail/Transactions.vue";
import Contracts from "./Detail/Contracts.vue";
import EventMobile from "./Detail/EventMobile.vue";
import { UserApi } from "@/networks/UserApi";
import { AxiosResponse } from "axios";
@Component({
  components: { Events, Transactions, Contracts, EventMobile }
})
export default class component_name extends Vue {
  tab = 0;
  desktop = window.innerWidth > 600 ? true : false;
  user: any = null;
  contracts = [];
  events = [];
  transactions = [];
  async mounted() {
    if (this.$route.query.userId) {
      let id: any = this.$route.query.userId;
      const { data } = await new UserApi().getUser(id);
      this.user = data.data;
    } else {
      this.user = JSON.parse(localStorage.getItem("user") as string);
    }
    try {
      const { data }: AxiosResponse = await new UserApi().getUserFileDetail(
        this.user.id
      );
      this.contracts = data.data.contract;
      console.log("data.data.event", data.data);
      this.events = data.data.event;
      this.transactions = data.data.transactions;
    } catch (err: any) {
      console.log(err.message);
    }
  }
}
