import { render, staticRenderFns } from "./Events.vue?vue&type=template&id=9a5311c4&scoped=true&"
import script from "./Events.vue?vue&type=script&lang=ts&"
export * from "./Events.vue?vue&type=script&lang=ts&"
import style0 from "./Events.vue?vue&type=style&index=0&id=9a5311c4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a5311c4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VSimpleTable})
