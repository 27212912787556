















































































































import { Vue, Component, Prop, Watch, Emit, Ref } from "vue-property-decorator";
import Enum from "@/config/enum";
@Component
export default class EventMobile extends Vue {
  @Prop()
  events!: any;
  private eventStatus = Enum.eventStatusAdmin;
}
