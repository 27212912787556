

























































































import { Vue, Component, Prop, Watch, Emit, Ref } from "vue-property-decorator";
import moment from "moment-jalaali";
@Component
export default class component_name extends Vue {
  @Prop()
  contract!: any;
  private baseUrl = process.env.VUE_APP_URL || "";
  ContractStatus(status: any) {
    return "موفق";
  }
  ContractStatusColor(status: any) {
    return "green";
  }
  ContractDate(date: any) {
    return moment(date).format("hh:mm | jYYYY jMMMM jDD");
  }
  ContractOffice(office: any) {
    return "تهران-جردن";
  }
}
